:root {
  color-scheme: only light !important;
}
@media (min-width: 992px) {
  .app-header {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .layout-padding {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
}
@media (max-width: 991.98px) {
  .app-header {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .layout-padding {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}
.fs-12 {
  font-size: 0.923rem !important;
}
.fs-14 {
  font-size: 1.077rem !important;
}
.fs-15 {
  font-size: 1.154rem !important;
}
.fs-16 {
  font-size: 1.231rem !important;
}
.fs-18 {
  font-size: 1.385rem !important;
}
.fs-20 {
  font-size: 1.538rem !important;
}
.fs-22 {
  font-size: 1.692rem !important;
}
.fs-24 {
  font-size: 1.846rem !important;
}
.fs-26 {
  font-size: 2rem !important;
}
.fs-28 {
  font-size: 2.154rem !important;
}
.fs-30 {
  font-size: 2.308rem !important;
}
.fs-32 {
  font-size: 2.462rem !important;
}
.fs-34 {
  font-size: 2.615rem !important;
}
.fs-35 {
  font-size: 2.692rem !important;
}
.fs-36 {
  font-size: 2.769rem !important;
}
.fs-38 {
  font-size: 2.923rem !important;
}
.fs-40 {
  font-size: 3.077rem !important;
}
.fs-42 {
  font-size: 3.231rem !important;
}
.fs-44 {
  font-size: 3.385rem !important;
}
.fs-46 {
  font-size: 3.538rem !important;
}
.fs-48 {
  font-size: 3.692rem !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-800 {
  font-weight: 800 !important;
}
.fw-900 {
  font-weight: 900 !important;
}
.fw-bold {
  font-weight: bold !important;
}
.fw-normal {
  font-weight: normal !important;
}
.text-gray {
  color: #7c7c7c !important;
}
.text-black {
  color: #1a1a1a !important;
}
.text-italic {
  font-style: italic !important;
}
.carousel-custom .carousel-indicators.carousel-indicators-dots li.active:after {
  background-color: #1b74e4 !important;
}
.carousel-custom .carousel-indicators.carousel-indicators-dots li:after {
  background-color: #b3c7f5 !important;
}
.form-control-custom {
  background-color: #f9f9f9;
  color: #1a1a1a;
  transition: color 0.2s ease;
  min-height: calc(1.88em + 1.533rem + 10px);
  padding: 0.825rem 1.5rem;
  font-size: 1.231rem;
  font-weight: 600;
  border-radius: 8px;
  border: solid 0.5px #e0e0df;
}
.form-control-custom:focus,
.form-control-custom:active {
  background-color: #f9f9f9;
}
.form-control-table {
  font-size: 1.154rem;
  font-weight: 600;
  color: #1a1a1a;
  padding: 0.79rem 1.077rem;
  border-radius: 5px;
  min-height: calc(1rem + 1rem + 10px);
  border: solid 0.5px #e0e0df;
  background-color: #f9f9f9;
  transition: color 0.2s ease;
}
.btn {
  font-size: 1.231rem;
  font-weight: bold;
}
.custom-phone-input {
  border-left: 0px !important;
}
.auth-footer {
  position: absolute;
  bottom: -50%;
  left: 50%;
  margin-bottom: 10px;
  transform: translate(-50%, 0%);
}
.play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.play-btn:hover {
  transition: 0.2s all ease-in;
}
@media (min-width: 992px) {
  .auth-footer-2 {
    position: absolute;
    bottom: -50%;
    left: 50%;
    margin-bottom: 10px;
    transform: translate(-50%, -0%);
  }
}
@media (max-width: 576px) {
  .br {
    display: none;
  }
  .custom-alert {
    padding: 15px;
    border-radius: 8px;
  }
  .custom-alert h2 {
    font-size: 18px;
    font-weight: bold;
    color: #1a1a1a;
  }
  .custom-alert p {
    font-size: 15px;
    font-weight: 500;
    color: #1a1a1a;
  }
}
@media (max-width: 992px) {
  .br-md {
    display: none;
  }
}
.otp-group {
  display: flex;
  width: 100%;
  max-width: 360px;
  column-gap: 10px;
  margin: 0 15px;
}
.otp-input {
  width: 100%;
  height: 60px;
  border: solid 0.5px #e0e0df !important;
  background-color: #f9f9f9 !important;
  border-radius: 8px;
  text-align: center;
  line-height: 1;
}
.custom-alert {
  padding: 25px;
  border-radius: 10px;
}
.custom-alert h4 {
  font-size: 20px;
  font-weight: bold;
  color: #1a1a1a;
}
.custom-alert p {
  font-size: 16px;
  font-weight: 500;
  color: #1a1a1a;
}
.kyc-page {
  width: 100%;
  overflow: hidden;
  height: 100vh;
}
.kyc-process {
  padding: 0px 30px 30px;
  width: 100%;
  height: calc(100vh - 200px);
  overflow-y: auto;
}
.stepper-item.current {
  display: block;
}
.kyc-content-footer {
  border-top: 0.5px solid #e0e0df;
  background-color: #fff;
  position: absolute;
  bottom: 0%;
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
}
.stepper-item {
  display: none;
}
.custom-stepper.stepper.stepper-links .stepper-nav .stepper-item:after {
  display: none;
}
.custom-stepper.stepper.stepper-links .stepper-nav .stepper-item.current .stepper-title {
  color: #7c7c7c;
  margin-bottom: 0px;
}
.kyc-process label {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}
.border-r4px {
  border-radius: 0.308rem !important;
}
.border-r6px {
  border-radius: 0.462rem !important;
}
.border-r8px {
  border-radius: 0.615rem !important;
}
.border-r10px {
  border-radius: 0.769rem !important;
}
.border-r15px {
  border-radius: 1.154rem !important;
}
.border-r23px {
  border-radius: 1.769rem !important;
}
.border-r0px-top {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.border-r10px-bottom {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.border-r10px-top {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}
.btnPrev,
.prevtab {
  padding: 1.231rem 32px;
  min-width: 114px;
  height: 50px;
  font-size: 1.231rem;
  font-weight: bold;
  color: #1b74e4 !important;
  background-color: #e7f1fd !important;
  margin-right: -8px;
  border-radius: 8px 0px 0px 8px;
  position: sticky;
  z-index: 2;
}
.btnNext,
.nexttab {
  padding: 1.231rem 32px;
  min-width: 114px;
  height: 50px;
  font-size: 1.231rem;
  font-weight: bold;
  color: #ffff !important;
  background-color: #1b74e4 !important;
  border-radius: 8px;
  z-index: 1;
}
.pills-radius {
  border-radius: 30px;
}
.w-fit-content {
  width: fit-content !important;
}
.btn.btn-primary.btnEdit:hover:not(.btn-active) {
  height: 50px;
  padding: 15px 22px;
  border-radius: 8px;
  background-color: #f9f9f9 !important;
}
.btnEdit {
  height: 50px;
  padding: 15px 22px;
  border-radius: 8px;
  background-color: #f9f9f9 !important;
}
.bgi-contain {
  background-size: contain !important;
}
.close-top {
  position: absolute;
  top: -3%;
  right: -3%;
}
.close-top-15px {
  position: absolute;
  top: 15%;
  right: 15%;
}
.modal-content {
  border-radius: 10px;
}
.close-inner-top {
  position: absolute;
  top: 7%;
  left: 3%;
}
.close-inner-top-2 {
  position: absolute;
  top: -1%;
  right: -1%;
}
.close-top-2 {
  position: absolute;
  top: -6%;
  right: -6%;
  width: 30px;
}
@media (min-width: 992px) {
  .close-inner-top-2 {
    position: absolute;
    top: 4%;
    left: 3%;
  }
  .close-top-2 {
    position: absolute;
    top: -6%;
    right: -6%;
    width: 35px;
  }
}
.img-crop-box {
  background-color: #00000008;
  border-radius: 10px;
  min-height: 300px;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.upload-btn-wrapper input[type='file'] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  color: transparent;
}
.cropper-modal .close-inner-top {
  top: 3.5%;
  left: 2%;
}
@media (min-width: 992px) {
  .btn-lg {
    font-size: 1.231rem !important;
    font-weight: bold !important;
    border-radius: 0.615rem;
    height: 55px;
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
  }
}
.btn-lg {
  font-weight: 600 !important;
}
.details-pending {
  border: solid 0.5px #e0e0df;
  border-radius: 10px;
  .details {
    border-bottom: solid 0.5px #e0e0df;
    padding: 18px 20px;
    font-size: 1.385rem;
    font-weight: 500;
  }
  .details:last-child {
    border-bottom: none;
  }
}
.nav-line-tabs .nav-item .nav-link {
  color: #1a1a1a;
}
.custom-accordion {
  .card-header {
    padding: 0px 1.5rem;
  }
  .accordion-button::after {
    width: 17px;
    height: 9px;
    background-image: url('../../../assets/media/svg_uTrade/down-arrow.svg');
  }
  .accordion-button:not(.collapsed) {
    background-color: #f9f9f9;
  }
}
.form-check.form-check-solid .form-check-input:not(:checked) {
  background-color: #efefef !important;
}
.line-clamp-1 {
  width: 200px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.green-tick-li {
  ul {
    margin: 0 0 1em 0;
    padding: 0 0 0 1em;
    list-style: none;
  }
  li {
    padding-left: 0.4em;
  }
  li:before {
    content: '';
    background: url('../../media/svg_uTrade/green-tick.svg');
    background-repeat: no-repeat;
    position: relative;
    background-size: contain;
    display: inline-block;
    width: 16px;
    height: 16px;
    top: 0.34rem;
    margin-right: 8px;
  }
}
.form-check-input.form-check-success:checked {
  background-color: #4cc320;
  border-color: #4cc320;
}
.error-bg {
  background: rgb(255, 141, 0);
  background: linear-gradient(
    200deg,
    rgba(255, 141, 0, 1) 0%,
    rgba(240, 139, 15, 0.896796218487395) 14%,
    rgba(27, 116, 228, 1) 63%
  );
}
@media (max-width: 768px) {
  .error-bg {
    background: rgb(255, 141, 0);
    background: linear-gradient(
      386deg,
      rgb(255, 141, 0) 0%,
      rgba(240, 139, 15, 0.8967962185) 14%,
      rgb(27, 116, 228) 63%
    );
  }
}
input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
  display: none;
}
.bg-gray-dark {
  background-color: #efefef !important;
}
.dimension.error::after {
  color: #e55451;
  content: '\f06a';
  font-family: FontAwesome;
  display: inline-block;
  padding-left: 6px;
  vertical-align: baseline;
  font-size: 18px;
}
.form-check-label {
  display: flex;
  flex: inherit;
}
.form-control-custom {
  background-color: var(--bs-gray-100);
}
.form-control {
  border-radius: 8px !important;
}
.input-group {
  border-radius: 8px !important;
}
.form-control-custom {
  background-color: var(--bs-gray-100);
  color: #1a1a1a !important;
  transition: color 0.2s ease;
  min-height: calc(1.88em + 1.591rem + 10px);
  padding: 0.825rem 1.5rem;
  font-size: 1.231rem !important;
  font-weight: 600 !important;
  color: #1a1a1a !important;
  border-radius: 8px;
  border: solid 0.5px #e0e0df;
}
.form-control-custom:active,
.form-control-custom:focus {
  background-color: var(--bs-gray-100);
}
.form-control {
  border-radius: 0.615rem !important;
}
.custom-tabContainer {
  .nav {
    --bs-nav-link-padding-x: 1.5rem;
    --bs-nav-link-padding-y: 0.8rem;
  }
}
.custom-phone-input {
  border-left: 0px !important;
}
.auth-footer {
  position: absolute;
  bottom: -50%;
  left: 50%;
  margin-bottom: 10px;
  transform: translate(-50%, 0%);
}
.play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.play-btn:hover {
  transition: 0.2s all ease-in;
}
@media (min-width: 992px) {
  .auth-footer-2 {
    position: absolute;
    bottom: -50%;
    left: 50%;
    margin-bottom: 10px;
    transform: translate(-50%, -0%);
  }
}
@media (max-width: 576px) {
  .br {
    display: none;
  }
  .custom-alert {
    padding: 15px;
    border-radius: 8px;
  }
  .custom-alert h2 {
    font-size: 18px;
    font-weight: bold;
    color: #1a1a1a;
  }
  .custom-alert p {
    font-size: 15px;
    font-weight: 500;
    color: #1a1a1a;
  }
}
@media (max-width: 992px) {
  .br-md {
    display: none;
  }
}
.otp-group {
  display: flex;
  width: 100%;
  max-width: 360px;
  column-gap: 10px;
  margin: 0 15px;
}
.otp-input {
  width: 100%;
  height: 60px;
  border: solid 0.5px #e0e0df !important;
  background-color: #f9f9f9 !important;
  border-radius: 8px;
  text-align: center;
  line-height: 1;
}
.custom-alert {
  padding: 25px;
  border-radius: 10px;
}
.custom-alert h4 {
  font-size: 20px;
  font-weight: bold;
  color: #1a1a1a;
}
.custom-alert p {
  font-size: 16px;
  font-weight: 500;
  color: #1a1a1a;
}
.kyc-page {
  width: 100%;
  overflow: hidden;
  height: 100vh;
}
.kyc-process {
  padding: 0px 30px 30px;
  width: 100%;
  height: calc(100vh - 200px);
  overflow-y: auto;
}
.stepper-item.current {
  display: block;
}
.kyc-content-footer {
  border-top: 0.5px solid #e0e0df;
  background-color: #fff;
  position: absolute;
  bottom: 0%;
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
}
.stepper-item {
  display: none;
}
.custom-stepper.stepper.stepper-links .stepper-nav .stepper-item:after {
  display: none;
}
.custom-stepper.stepper.stepper-links .stepper-nav .stepper-item.current .stepper-title {
  color: #7c7c7c;
  margin-bottom: 0px;
}
.kyc-process label {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}
.border-r4px {
  border-radius: 0.308rem !important;
}
.border-r6px {
  border-radius: 0.462rem !important;
}
.border-r8px {
  border-radius: 0.615rem !important;
}
.border-r10px {
  border-radius: 0.769rem !important;
}
.border-r15px {
  border-radius: 1.154rem !important;
}
.border-r23px {
  border-radius: 1.769rem !important;
}
.border-r0px-top {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.border-r8px-bottom {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.border-r10px-bottom {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.border-r10px-top {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}
.btnPrev,
.prevtab {
  padding: 16px 32px;
  width: 114px;
  height: 50px;
  font-size: 16px;
  font-weight: bold;
  color: #1b74e4 !important;
  background-color: #e7f1fd !important;
  margin-right: -8px;
  border-radius: 8px 0px 0px 8px !important;
  position: sticky;
  // z-index: 0;
}
.btnNext,
.nexttab {
  padding: 16px 32px;
  width: 114px;
  height: 50px;
  font-size: 16px;
  font-weight: bold;
  color: #ffff !important;
  background-color: #1b74e4 !important;
  border-radius: 8px;
}
.pills-radius {
  border-radius: 30px;
}
.w-fit-content {
  width: fit-content;
}
.btn.btn-primary.btnEdit:hover:not(.btn-active) {
  height: 50px;
  padding: 15px 22px;
  border-radius: 8px;
  background-color: #f9f9f9 !important;
}
.btnEdit {
  height: 50px;
  padding: 15px 22px;
  border-radius: 8px;
  background-color: #f9f9f9 !important;
}
.bgi-contain {
  background-size: contain !important;
}
.close-top {
  position: absolute;
  top: -3%;
  right: -3%;
}
.close-top-15px {
  position: absolute;
  top: 15%;
  right: 15%;
}
.modal-content {
  border-radius: 10px;
}
.close-inner-top {
  position: absolute;
  top: 7%;
  left: 3%;
}
.close-inner-top-2 {
  position: absolute;
  top: -1%;
  right: -1%;
}
.close-top-2 {
  position: absolute;
  top: -6%;
  right: -6%;
  width: 30px;
}
@media (min-width: 992px) {
  .close-inner-top-2 {
    position: absolute;
    top: 4%;
    left: 3%;
  }
  .close-top-2 {
    position: absolute;
    top: -6%;
    right: -6%;
    width: 35px;
  }
}
.img-crop-box {
  background-color: #00000008;
  border-radius: 10px;
  min-height: 300px;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.upload-btn-wrapper input[type='file'] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  color: transparent;
}
.cropper-modal .close-inner-top {
  top: 3.5%;
  left: 2%;
}
@media (min-width: 992px) {
  .btn-lg {
    font-size: 1.231rem !important;
    font-weight: bold !important;
    border-radius: 0.615rem;
    height: 55px;
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
  }
}
.details-pending {
  border: solid 0.5px #e0e0df;
  border-radius: 10px;
  .details {
    border-bottom: solid 0.5px #e0e0df;
    padding: 18px 20px;
    font-size: 1.385rem;
    font-weight: 500;
  }
  .details:last-child {
    border-bottom: none;
  }
}
.nav-line-tabs .nav-item .nav-link {
  color: #1a1a1a;
}
.custom-accordion {
  .card-header {
    padding: 0px 1.5rem;
  }
  .accordion-button::after {
    width: 17px;
    height: 9px;
    background-image: url('../../../assets/media/svg_uTrade/down-arrow.svg');
  }
  .accordion-button:not(.collapsed) {
    background-color: #f9f9f9;
  }
}
.form-check.form-check-solid .form-check-input:not(:checked) {
  background-color: #efefef !important;
}
.line-clamp-1 {
  width: 200px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.green-tick-li {
  ul {
    margin: 0 0 1em 0;
    padding: 0 0 0 1em;
    list-style: none;
  }
  li {
    padding-left: 0.4em;
  }
  li:before {
    content: '';
    // left: -1em;
    // top: 0.65em;
    background: url('../../media/svg_uTrade/green-tick.svg');
    background-repeat: no-repeat;
    position: relative;
    background-size: contain;
    display: inline-block;
    width: 16px;
    height: 16px;
    top: 0.34rem;
    margin-right: 8px;
  }
}
.form-check-input.form-check-success:checked {
  background-color: #4cc320;
  border-color: #4cc320;
}
.error-bg {
  background: rgb(255, 141, 0);
  background: linear-gradient(
    200deg,
    rgba(255, 141, 0, 1) 0%,
    rgba(240, 139, 15, 0.896796218487395) 14%,
    rgba(27, 116, 228, 1) 63%
  );
}
@media (max-width: 768px) {
  .error-bg {
    background: rgb(255, 141, 0);
    background: linear-gradient(
      386deg,
      rgb(255, 141, 0) 0%,
      rgba(240, 139, 15, 0.8967962185) 14%,
      rgb(27, 116, 228) 63%
    );
  }
}
// Hide password eye from Edge
input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
  display: none;
}
.bg-gray-dark {
  background-color: #efefef;
}
// Custom Tab Container
.custom-tabContainer {
  .nav {
    --bs-nav-link-padding-x: 1.5rem;
    --bs-nav-link-padding-y: 0.8rem;
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #ffff;
  }
  .nav-item .nav-link {
    font-size: 1.231rem;
    font-weight: bold;
    color: #7c7c7c;
    display: inline-flex;
    align-items: center;
  }
}
.nav-item .check-after::after {
  content: '\f00c';
  font-family: FontAwesome;
  display: inline-block;
  padding-left: 6px;
  vertical-align: baseline;
  font-size: 18px;
}
.nav-item .error::after {
  color: #e55451;
  content: '\f06a';
  font-family: FontAwesome;
  display: inline-block;
  padding-left: 6px;
  vertical-align: baseline;
  font-size: 18px;
}
.dimension.error::after {
  color: #e55451;
  content: '\f06a';
  font-family: FontAwesome;
  display: inline-block;
  padding-left: 6px;
  vertical-align: baseline;
  font-size: 18px;
}
.form-check-label {
  display: flex;
  flex: inherit;
}
.btn {
  font-size: 16px !important;
  font-weight: bold !important;
}
.text-decoration-line-through {
  text-decoration: line-through !important;
}
// Color
.bg-efefef {
  background-color: #efefef !important;
}
.bg-fff4d9 {
  background-color: #fff4d9 !important;
}
.table-modal-close {
  position: absolute;
  top: 4%;
  left: 3%;
}
.btn-table {
  font-size: 1.077rem;
  font-weight: 600;
  min-height: 40px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}
// Border Radius ----------------------------------------------------------------------------------------------
.border-rs8px {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}
.border-re8px {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
// Background Colors ------------------------------------------------------------------------------------------
.bg-d4e1fc {
  background-color: #d4e1fc !important;
}
.bg-ccebfd {
  background-color: #ccebfd !important;
}
.bg-ccf3f0 {
  background-color: #ccf3f0 !important;
}
.bg-fbeabc {
  background-color: #fbeabc !important;
}
.bg-fbe5e5 {
  background-color: #fbe5e5 !important;
}
.bg-efefef {
  background-color: #efefef !important;
}
.bg-e7f1fd {
  background-color: #e7f1fd !important;
}
.bg-e5f6de {
  background-color: #e5f6de !important;
}
.bg-f9f9f9 {
  background-color: #f9f9f9 !important;
}
.bg-c9f3d7 {
  background-color: #c9f3d7 !important;
}
.bg-fbeace {
  background-color: #fbeace !important;
}
.bg-d5d5f2 {
  background-color: #d5d5f2 !important;
}
.bg-c6e4fb {
  background-color: #c6e4fb !important;
}
// table button
.btn-table {
  font-size: 1.077rem !important;
  font-weight: 600;
  min-height: 40px;
}
.breakWord-nextLine {
  white-space: normal !important; /* Allow text to wrap */
  word-wrap: break-word; /* Break word if it exceeds column width */
}
